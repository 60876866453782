<template>
  <div
    class="row mb-2"
  >
    <div 
      :class="widthClass"
    >
      <dropdown
        v-if="!disabled"
        label="Groep toevoegen"
        :options="groupOptions" 
        @update:value="addGroup"
      />
    </div>
  </div>

  <loading-spinner 
    v-if="loading"
    text-align="center"
  />
  
  <div 
    v-if="!loading"
    class="row"
  >
    <div
      v-for="group in displayedGroups" 
      :key="group.id" 
      class="my-3"
      :class="groupWidth"
    >
      <group-card
        :group="group"
        @deleted="markGroupAsDeleted"
      />
    </div>

    <list-cutoff
      v-if="displayedGroupCount < filteredGroups.length"
      :show-all-button-text="`Toon alle groepen (${filteredGroups.length})`"
      @show-all-button-pressed="onShowAllButtonPressed"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/UI/Dropdown.vue';
import { getAllGroups } from '@/api/providers/groups';

import GroupCard from '@/components/Shared/GroupList/GroupCard.vue';

import { toRaw } from 'vue';
import ListCutoff from '@/components/UI/ListCutoff.vue';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';


export default {
  components: {
    Dropdown,
    ListCutoff,
    LoadingSpinner,
    GroupCard
  },

  props: {
    groupIds: { type: Array, required: true },
    showUpdate: { type: Boolean, default: true },
    addGroupCallback: { type: Function, required: true },
    removeGroupCallback: { type: Function, required: true },
    fullWidth: { type: Boolean, required: false },
    disabled: { type: Boolean, default: false },
    initialMaxGroupCount: { type: Number, default: Number.MAX_SAFE_INTEGER, },
  },

  data() {
    return {
      groupOptions: [],
      selectedGroups: [],
      displayedGroupCount: this.initialMaxGroupCount,
      loading: true,
    };
  },

  computed: {
    displayedGroups() {
      return this.selectedGroups.slice(0, this.displayedGroupCount);
    },

    filteredGroups() { 
      return this.selectedGroups.filter(g => !g.isDeleted); 
    },

    addedGroupIds() {
      return this.selectedGroups
        .filter(g => g.isNew && !g.isDeleted)
        .map(g => g.id);
    },

    removedGroupIds() {
      return this.selectedGroups
        .filter(g => g.isDeleted && !g.isNew)
        .map(g => g.id)
    },

    widthClass() { 
      return this.fullWidth
        ? 'col-12'
        : 'col-4';
    },
    groupWidth() {
      return this.fullWidth 
        ? 'col-sm-12'
        : 'col-sm-4';
    }
  },

  watch: {
    groupOptions(current) {
      this.initializeGroupVisuals(this.groupIds, current);
    },
    groupIds(current) {
      this.initializeGroupVisuals(current, this.groupOptions);
    }
  },

  async mounted() {
    await this.getGroups();
  },

  methods: {
    async getGroups() {
      try {
        let groups = await getAllGroups() ?? [];

        this.groupOptions = groups.map(g => {
          return {
            value: g.id,
            label: g.title,
          }
        });
      }
      catch {
        this.groupOptions = [];
      }
    },

    initializeSelectedGroups() {
      const groupIds = this.groupIds.map(g => toRaw(g));

      const options = this.groupOptions.filter(o => 
        (groupIds ?? []).includes(Number(o.value))
      ).map(o => toRaw(o));

      const groupList = [];
      for (const option of options) {
        groupList.push({
          id: option.value,
          title: option.label,
        });
      }

      this.selectedGroups = groupList;
    },

    addGroup(id) {
      if (this.selectedGroups.find(u => u.id === id) != null) return;

      let option = this.groupOptions.find(o => o.value === id);
      if (option == null) return;

      this.selectedGroups.push({
        id: option.value,
        title: option.label,
        isNew: true
      });
    },

    async submit(entityId) {
      await Promise.all([
        this.submitAddedGroups(entityId),
        this.submitRemovedGroups(entityId)
      ]);
    },

    async submitAddedGroups(entityId) {
      const groupIds = this.selectedGroups
          .filter(u => u.isNew && !u.isDeleted)
          .map(u => u.id);

      if (groupIds.length < 1) return;

      await this.addGroupCallback(entityId, groupIds);
    },

    async submitRemovedGroups(entityId) {
      let groupIds = this.selectedGroups
          .filter(u => u.isDeleted && !u.isNew)
          .map(u => u.id);

      if (groupIds.length < 1) return;

      await this.removeGroupCallback(
        entityId,
        groupIds
      );
    },

    markGroupAsDeleted(id) {
      const groupIndex = this.selectedGroups.findIndex(u => u.id === id);
      if (groupIndex < 0) return;

      this.selectedGroups[groupIndex] = { 
        ...this.selectedGroups[groupIndex], 
        isDeleted: true
      };
    },

    onShowAllButtonPressed() {
      this.displayedGroupCount = Number.MAX_SAFE_INTEGER
    },

    initializeGroupVisuals(groupIds, groupOptions) {
      if (!this.loading) return;

      const groupIdsLoading = toRaw(groupIds) == null;
      const groupsLoading = toRaw(groupOptions) == null;

      if (groupIdsLoading || groupsLoading) return;

      this.loading = false;
      this.initializeSelectedGroups();
    },

  },
}
</script>